@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');


body {
  margin: 0;
  -webkit-font-smoothing: "lato", serif;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box !important;
  /* word-wrap: break-word !important;
  word-break: break-word !important; */
  font-family: "lato", serif;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiTypography-root.MuiTypography-h1,
.MuiTypography-root.MuiTypography-h2,
.MuiTypography-root.MuiTypography-h3,
.MuiTypography-root.MuiTypography-h4,
.MuiTypography-root.MuiTypography-h5,
.MuiTypography-root.MuiTypography-h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "lato", serif;
  line-height: 130% !important;
}

p,
.p,
.body1,
body1,
.MuiTypography-root.MuiTypography-body1 {
  font-family: "lato", sans-serif !important;
  line-height: 25px !important;
  font-weight: 400 !important;
  font-size: 0.9rem !important;
  /* text-align: justify; */
}

.calendly-inline-widget {
  height: 750px !important;
  margin-bottom: 5vh !important;
}

@media only screen and (max-width: 600px) {

  p,
  .p,
  .body1,
  body1,
  .MuiTypography-root.MuiTypography-body1 {
    font-size: 0.8rem !important;
  }

  .calendly-inline-widget {
    height: 850px !important;
  }
}

.header {
  font-family: "lato", serif;
  font-weight: 500 !important;
  font-size: 2.2rem !important;
}

.subheader {
  font-family: "lato", serif;
  font-weight: 300 !important;
  font-size: 1.3rem !important;
  color: #000d2f;
  margin-bottom: 1.2rem !important;
}

@media only screen and (max-width: 600px) {
  .header {
    font-size: 1.6rem !important;
  }

  .subheader {
    font-size: 1.1rem !important;
  }
}

.core-image {
  object-fit: cover;
  object-position: center;
}



@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in {
  animation: slideIn 0.8s ease-out forwards;
  opacity: 0;
}




/* Slide-in from bottom */
@keyframes slideInUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Fade-in */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Apply these animations */
.text-animate {
  opacity: 0;
  transform: translateY(20px);
  /* Initial hidden state */
  transition: all 1s ease-out;
}

.text-animate.animated {
  opacity: 1;
  transform: translateY(0);
  /*Finalstateafteranimation*/
  animation: slideInUp 1s ease-out forwards;
  -webkit-animation: slideInUp 1s ease-out forwards;
}

.image-animate {
  opacity: 0;
  transition: opacity 1s ease-out;
}

.image-animate.animated {
  opacity: 1;
  animation: fadeIn 1s ease-out forwards;
}




/* //split text  */

.text-2xl {

  font-size: 2rem !important;
  font-weight: bold !important;


}